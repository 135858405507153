import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Donut from "../images/svgs/mini-donut.svg"
import Headline from "../components/headline"
import Map from "../components/googleMap"
import IceCreamCup from "../images/svgs/ice-cream-cup.svg"

export default function TradingHours({ data }) {
  //Wordpress data
  const array = data.allWpPage.edges[0].node.tradingHours || []

  const header = array.header
  const location1Name = array.location1.name
  const location1Address = array.location1.address
  const location1Array = array.location1.thours
  const location2Name = array.location2.name
  const location2Address = array.location2.address
  const locations2Array = array.location2.thours

  return (
    <Layout>
      <SEO title="Trading Hours" />
      <section className="trading-hours mt-20 mb-20">
        <div className="container">
          <Headline
            title={header}
            icon={<Donut className="invisible md:visible  h-auto" />}
          />
          <div className="row trading-hours text-center mt-8">
            <div className="col-6">
              <h2 className="m-0">{location1Name}</h2>
              <h3>{location1Address}</h3>
              <ul>
                {location1Array.map(item => {
                  return <li>{item.hours}</li>
                })}
              </ul>
            </div>
            <div className="col-6">
              <h2 className="mt-6 mb-0 lg:m-0">{location2Name}</h2>
              <h3>{location2Address}</h3>
              <ul>
                {locations2Array.map(item => {
                  return <li>{item.hours}</li>
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="location mt-24">
        <Headline
          title="Our Locations"
          icon={<IceCreamCup className="invisible md:visible  h-auto" />}
        />
        <Map />
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query TradingHoursPageQuery {
    allWpPage(filter: { id: { eq: "cG9zdDo2NDY=" } }) {
      edges {
        node {
          tradingHours {
            header
            location1 {
              address
              name
              thours {
                hours
              }
            }
            location2 {
              address
              name
              thours {
                hours
              }
            }
          }
        }
      }
    }
  }
`
